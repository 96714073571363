import React from 'react'
import { Link } from "gatsby"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogPage = ({ data }) => (
  <Layout>
    <SEO title="Blog" />
    <div>
      {data.allMarkdownRemark.edges.map(post => (
        <div
          key={post.node.id}
          style={{
            marginTop: `70px`,
            marginBottom: `110px`,
          }}
        >
          <p
            style={{
              fontSize: `19px`,
              color: `#78757a`,
            }}
          >
            {post.node.frontmatter.date}
          </p>
          <Link to={post.node.frontmatter.path}>
            <h3
              style={{
                fontSize: `40px`,
                fontWeight: `700`,
                lineHeight: `1.3`,
                textDecoration: `underline #f67300`,
              }}
            >
              {post.node.frontmatter.title}
            </h3>
          </Link>
          <Link to={post.node.frontmatter.path}>
            <p>
              {post.node.excerpt}
            </p>
          </Link>
          <Link to={post.node.frontmatter.path}
            style={{
              fontSize: `17px`,
              color: `#78757a`,
            }}
            styleH
          >Read More &#8594;</Link>
        </div>
      ))}
    </div>
  </Layout>
)

export const pageQuery = graphql`
  query BlogIndexQuery {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          excerpt(pruneLength: 280)
          frontmatter {
            date
            path
            title
          }
        }
      }
    }
  }
`
export default BlogPage
